import { Loading } from 'element-ui'

// let loadingCount = 0;
let loading

// const startLoading = () => {
//   loading = Loading.service({
//     lock: true,
//     text: '数据获取中......',
//     background: 'rgba(0, 0, 0, 0.7)'
//   });
// };

// const endLoading = () => {
//   console.log(loading)
//   if(loading!=null){
//     loading.close();
//   }
// };

// export const showLoading = () => {
//   // if (loadingCount === 0) {
//     startLoading();
//   // }
//   // loadingCount += 1;
// };

// export const hideLoading = () => {

//   endLoading();

//   // if (loadingCount <= 0) {
//   //   console.log('loadingCount='+loadingCount)
//   //   return;
//   // }
//   // loadingCount -= 1;
//   // if (loadingCount === 0) {
//   //   endLoading();
//   // }
// };

// loading实例
let loadingInstance = null
// 计数器
let loadingCount = 0

// 关闭loading框
export const hideLoading = () => {
  console.log(loadingInstance)
  if (loadingCount > 0) {
    loadingCount--
  }
  if (loadingInstance && loadingCount === 0) {
    loadingInstance.close()
    loadingInstance = null
  }
}

// 开启loading框
export const showLoading = ({
  text = '数据加载中...',
  fullscreen = true,
  spinner = 'el-icon-loading',
  needLoading = true
} = {}) => {
  loadingCount++
  console.log(`loadingCount=${loadingCount}`)
  // 上一个请求的loading还存在，就不需要继续生成loading服务
  if (needLoading && !loadingInstance) {
    loadingInstance = Loading.service({
      fullscreen,
      text,
      spinner,
      background: 'rgba(0, 0, 0, 0.7)'
    })
  }
}

// 登录时开启loading框
export const LoginLoading = ({
  text = '数据加载中...',
  fullscreen = true,
  spinner = 'el-icon-loading',
  needLoading = true
} = {}) => {
  loadingCount++
  console.log(`loadingCount=${loadingCount}`)
  // 上一个请求的loading还存在，就不需要继续生成loading服务
  if (needLoading && !loadingInstance) {
    loadingInstance = Loading.service({
      fullscreen,
      text,
      spinner,
      background: 'rgba(0, 0, 0, 0.7)'
    })
  }
}
