import CryptoJS from 'crypto-js';

export const PassWordDescrypt = (text) => {
    // 设置密码和初始化向量
    const password = 'zmkjpwd86982118a';
    const iv = '4570125797502478';

    // 将密码和初始化向量转换为字节数组
    const key = CryptoJS.enc.Utf8.parse(password);
    const ivBytes = CryptoJS.enc.Utf8.parse(iv);

    // 设置加密参数
    const options = {
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        iv: ivBytes,
        keySize: 128 / 8,
        blockSize: 128 / 8
    };
    const encrypted = CryptoJS.AES.encrypt(text, key, options);
    return encrypted.toString();
}