import JSEncrypt from 'jsencrypt'

//对接代理的加密和物联通加密 秘钥不同
function RSAencrypt(str, Name) {
  let rsaKey
  // 公钥
  if (Name == 'yun') {
    rsaKey = `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCgmosjuNq4iSS4gtjzL/os0yrP
    QOtDtj3JzJ5CUjBdnBrfC3mmkpvgK0FXk2k/Ae48uQzg0DfJZbQyg25ytO23eI21
    tZVL5loMrHtw7YYHAIUEV9lxsI+Uk1sLkf8JQevLoFXWrjLBqrTb8sIKbW0wqHxX
    649d7Yi3QFK/6mN0HQIDAQAB`
  } else if (Name == 'wlt') {
    rsaKey = `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC1OU3ENYTKSZw3X/3uFeMOds++
    dCcOI5wcJ4r3fy7GUKXwMjxOCnuuk0NwMlHiBSFiuhfasxdzxUHIhDk/QD6omLjA
    G29D1EdrwyhCloEr7HAl7SekYeVgwF1EzO4UPjeXL9rbJEu8I6JMmzvvdvP2LXRF
    btf6dG2dLMckILfHWwIDAQAB`
  }
  let encryptor = new JSEncrypt() // 新建JSEncrypt对象
  encryptor.setPublicKey(rsaKey) // 设置公钥
  let encrypted = encryptor.encryptLong(str) // 加密
  return encrypted
}

export default { RSAencrypt }
