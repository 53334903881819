import Vue from 'vue'
import dayjs from 'dayjs'
import md5 from 'js-md5'

import App from './App.vue'
import router from './router'
// http
import http from './utils/http'
// rsa
import secret from './utils/secret'
// 日期
import Aes from './utils/AES'
// 日期

// 数据加载中
import { showLoading, hideLoading, LoginLoading } from './utils/loading'
import { PassWordDescrypt } from './utils/AES'
import element from './plugins/element'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/reset.css'
import './assets/css/iconfont.css'

Vue.config.productionTip = false

Vue.use(element)
Vue.prototype.$http = http
Vue.prototype.$jsEncrypt = secret
Vue.prototype.$dayjs = dayjs
Vue.prototype.$showLoading = showLoading
Vue.prototype.$hideLoading = hideLoading
Vue.prototype.$loginLoading = LoginLoading
Vue.prototype.$md5 = md5
Vue.prototype.$aes = PassWordDescrypt

Vue.prototype.$RequestData = function () {
  return {
    RequestData: {}
  }
}

new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app')
