import { Button, Message, Form, FormItem, Input, Tooltip, Autocomplete,Dialog} from 'element-ui'

const element = {
  install(Vue) {
    Vue.use(Button)
    Vue.use(Form)
    Vue.use(FormItem)
    Vue.use(Input)
    Vue.use(Tooltip)
    Vue.use(Autocomplete)
    Vue.use(Dialog)

    Vue.prototype.$message = Message
  }
}
export default element
